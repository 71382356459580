<template>
  <b-navbar-nav class="nav">
    <b-nav-item>
      <li class="nav-item mr-auto mt-0">
        <span class="brand-logo">
          <b-img
            :src="appLogoImage"
            alt="logo"
          />
        </span>
      </li>
    </b-nav-item>
    <b-nav-item>
      <li class="nav-item ml-2 mt-1">
        <h4 class="brand-text">
          {{ appName }}
        </h4>
      </li>
    </b-nav-item>

  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem, BImg,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BNavbarNav, BNavItem, BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      appName, appLogoImage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    z-index: -1;

    &:not(.show) {
      pointer-events: none;
    }

    &.show {
      cursor: pointer;
      z-index: 10;
      opacity: 1;
    }
}
</style>
